<template>
  <div>
    <vs-prompt
      class="socios-table__prompt"
      :active.sync="activator"
      title="Historial de estado"
      accept-text="Aceptar"
      cancel-text="Descartar"
    >
      <vs-table
        class="mt-3"
        v-if="deliveryman"
        :data="deliveryman.historyDeliverymanStatuses"
      >
        <template slot="thead">
          <vs-th> Estado </vs-th>
          <vs-th> Observación </vs-th>
          <vs-th> Creado </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <vs-chip
                v-if="tr.status"
                color="primary"
                class="product-order-status"
              >
                {{ tr.status | allyStatus }}
              </vs-chip>
            </vs-td>
            <vs-td>{{ tr.observation }} </vs-td>
            <vs-td>{{ tr.created_at }} </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-prompt>
  </div>
</template>

<script>
export default {
  name: 'HistoryStateModal',
  data: () => ({}),
  props: {
    value: Boolean,
    deliveryman: Object,
  },
  computed: {
    activator: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped></style>
