<template>
  <div class="socios-table tw-relative">
    <SendNotificationPrompt v-model="sendNotificationPrompt" />
    <!-- Prompt Verify -->
    <ValidationObserver ref="oberserverStatus" v-slot="{ invalid }">
      <vs-prompt
        class="socios-table__prompt"
        :active.sync="togglePromptVerify"
        title="Verificar Socio Logistico"
        @accept="saveVerify(socioSeleccionado)"
        accept-text="Guardar"
        :is-valid="!invalid"
        cancel-text="Descartar"
        v-if="socioSeleccionado"
      >
        <vs-select
          class="w-full"
          label="Estado:"
          v-model="socioSeleccionado.data_status"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in verify_states"
          />
        </vs-select>

        <vs-textarea
          class="mt-3"
          v-model="observation"
          placeholder="Observación"
        />
        <vs-table
          stripe
          :data="socioSeleccionado.historyDataStatuses"
          max-items="3"
          pagination
        >
          <template slot="header">
            <h3>Historial</h3>
          </template>
          <template slot="thead">
            <vs-th> # </vs-th>
            <vs-th> Observación </vs-th>
            <vs-th> Estado </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].observation">
                {{ data[indextr].observation }}
              </vs-td>

              <vs-td :data="data[indextr].status">
                {{ data[indextr].status | dataStatus }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-prompt>
    </ValidationObserver>
    <!-- Prompt Verify -->

    <!-- Prompt recarga -->
    <recharge-deliveryman-modal
      @recharge-success="rechargeSuccess"
      v-model="activePromptRecarga"
      v-if="socioSeleccionado"
      :deliveryman="socioSeleccionado"
    />

    <recharge-points-modal
      v-model="activePromptRecargaPuntos"
      v-if="socioSeleccionado"
      :deliveryman="socioSeleccionado"
    />
    <history-state-modal
      v-model="activePromptHistory"
      v-if="socioSeleccionado"
      :deliveryman="socioSeleccionado"
    />
    <!-- ./Prompt recarga -->

    <!-- Prompt status -->
    <ValidationObserver ref="oberserverStatus" v-slot="{ invalid }">
      <vs-prompt
        title="Cambiar estatus contratista"
        class="socios-table__prompt"
        :active.sync="activePromptStatus"
        @accept="changeDeliverymanStatus"
        :is-valid="!invalid"
        accept-text="Guardar"
        cancel-text="Descartar"
      >
        <vs-select class="w-full" label="Status" v-model="status">
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in deliverymanStatuses"
          />
        </vs-select>

        <vs-textarea
          class="mt-3"
          v-model="observation"
          placeholder="Observación"
        />
        <vs-table
          v-if="socioSeleccionado"
          stripe
          :data="socioSeleccionado.historyDeliverymanStatuses"
          max-items="3"
          pagination
        >
          <template slot="header">
            <h3>Historial</h3>
          </template>
          <template slot="thead">
            <vs-th> # </vs-th>
            <vs-th> Estado </vs-th>
            <vs-th> Observación </vs-th>
            <vs-th> Creación </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].status">
                {{ data[indextr].status | deliverymanHistoryStatus }}
              </vs-td>

              <vs-td :data="data[indextr].observation">
                {{ data[indextr].observation }}
              </vs-td>

              <vs-td :data="data[indextr].created_at">
                {{ data[indextr].created_at }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-prompt>
    </ValidationObserver>
    <!-- ./Prompt status -->

    <!-- Prompt Deliveryman Level -->
    <ValidationObserver ref="oberserverStatus" v-slot="{ invalid }">
      <vs-prompt
        class="socios-table__prompt"
        :active.sync="togglePromptDeliverymanLevel"
        title="Nivel de socio lógistico"
        @accept="saveDeliverymanLevel(socioSeleccionado)"
        accept-text="Guardar"
        :is-valid="!invalid"
        cancel-text="Descartar"
        v-if="socioSeleccionado"
      >
        <vs-select
          class="w-full"
          label="Nivel:"
          v-model="socioSeleccionado.level"
        >
          <vs-select-item
            :key="index"
            :value="item.value"
            :text="item.text"
            v-for="(item, index) in levels"
          />
        </vs-select>

        <vs-textarea
          class="mt-3"
          v-model="observation"
          placeholder="Observación"
        />
        <vs-table
          stripe
          :data="socioSeleccionado.historyLevels"
          max-items="3"
          pagination
        >
          <template slot="header">
            <h3>Historial</h3>
          </template>
          <template slot="thead">
            <vs-th> # </vs-th>
            <vs-th> Observación </vs-th>
            <vs-th> Nivel </vs-th>
            <vs-th> Creación </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].id">
                {{ data[indextr].id }}
              </vs-td>

              <vs-td :data="data[indextr].observation">
                {{ data[indextr].observation }}
              </vs-td>

              <vs-td :data="data[indextr].level">
                {{ data[indextr].level }}
              </vs-td>

              <vs-td :data="data[indextr].created_at">
                {{ data[indextr].created_at }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-prompt>
    </ValidationObserver>
    <!-- Prompt Deliveryman Level -->

    <!-- Prompt Inventory sale  -->
    <ValidationObserver ref="oberserverStatus" v-slot="{ invalid }">
      <vs-prompt
        class="socios-table__prompt"
        :active.sync="activePromptInventorySale"
        title="Vender producto a aliado"
        @accept="saleProductToDeliveryman(socioSeleccionado)"
        accept-text="Vender"
        :is-valid="!invalid"
        cancel-text="Descartar"
        v-if="socioSeleccionado"
      >
        <div v-if="inventoryLoading">
          <p class="my-3 text-grey text-center">Cargando...</p>
        </div>
        <div v-else>
          <vs-select
            class="w-full"
            label="Producto:"
            v-model="currentSale.product"
          >
            <vs-select-item
              :key="index"
              :value="product"
              :text="product.name"
              v-for="(product, index) in inventory"
            />
          </vs-select>

          <vs-select
            class="w-full"
            label="Cuenta de depósito:"
            v-model="currentSale.account"
          >
            <vs-select-item
              :key="index"
              :value="account"
              :text="account.name"
              v-for="(account, index) in accounts"
            />
          </vs-select>

          <ValidationProvider
            name="Cantidad"
            v-slot="{ errors }"
            rules="required|numeric|min_value:1"
          >
            <vs-input
              v-model="currentSale.amount"
              label="Cantidad"
              class="w-full mt-3"
              type="number"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>

          <ValidationProvider
            name="Efectivo"
            v-slot="{ errors }"
            rules="min_value:0"
          >
            <vs-input
              v-model="currentSale.cash"
              label="Efectivo"
              class="w-full mt-3"
              type="number"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
          <ValidationProvider
            name="Puntos"
            v-slot="{ errors }"
            rules="min_value:0"
          >
            <vs-input
              v-model="currentSale.points"
              label="Puntos"
              class="w-full mt-3"
              type="number"
            />
            <span class="text-danger text-sm">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </vs-prompt>
    </ValidationObserver>
    <!-- Prompt Inventory sale Level -->

    <vx-card>
      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"></div>

        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4 tw-w-full"
            v-model="searchQuery"
            placeholder="Buscar..."
          />

          <vs-button
            type="border"
            class="mb-4 md:mb-0"
            @click="exportAllDeliverymen"
            >Exportar</vs-button
          >
          <vs-button class="mb-4 ml-3 md:mb-0" @click="openNotificationPrompt"
            >Enviar Notificación</vs-button
          >
        </div>
      </div>

      <div v-if="!loading && !deliverymenLoading">
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="agGridState.gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="agGridState.defaultColDef"
          :rowData="deliverymen"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        >
        </ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="agGridState.maxPageNumbers"
          v-model="currentPage"
        />
      </div>
      <div v-else class="h-64 my-24 flex justify-center items-center">
        <p class="text-grey">Cargando...</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import AppLoading from '@/components/AppLoading';
import CellRendererAmount from '@/components/cell-renderer/CellRendererAmount.vue';
// import socios from './data.json';
import { useAgGrid } from '@/composable/useAgGrid.js';
import {
  UPDATE_DATA_STATUS,
  UPDATE_DELIVERYMAN_LEVEL,
  UPDATE_DELIVERYMAN_STATUS,
  CREATE_INVENTORY_SALE,
} from '@/graphql/mutations';
import { ALL_DELIVERYMEN, ALL_INVENTORIES, ACCOUNTS } from '@/graphql/queries';
import { useQuery, useResult } from '@vue/apollo-composable';
import {
  computed,
  defineComponent,
  reactive,
  ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { AgGridVue } from 'ag-grid-vue';
import CellRendererDeliverymanLevel from './cell-renderer/CellRendererDeliverymanLevel';
import CellRendererEdit from './cell-renderer/CellRendererEdit';
import CellRendererHistoryState from './cell-renderer/CellRendererHistoryState';
import CellRendererImage from './cell-renderer/CellRendererImage';
import CellRendererRecarga from './cell-renderer/CellRendererRecarga';
import CellRendererRecargaPuntos from './cell-renderer/CellRendererRecargaPuntos';
import CellRendererInventorySales from './cell-renderer/CellRendererInventorySales';
import CellRendererStatus from './cell-renderer/CellRendererStatus';
import CellRendererVerify from './cell-renderer/CellRendererVerify';
import HistoryStateModal from './modals/HistoryStateModal.vue';
import RechargeDeliverymanModal from './modals/RechargeDeliverymanModal';
import RechargePointsModal from './modals/RechargePointsModal';
import SendNotificationPrompt from './SendNotificationPrompt.vue';
export default defineComponent({
  components: {
    CellRendererHistoryState,
    AgGridVue,
    CellRendererAmount,
    CellRendererStatus,
    CellRendererEdit,
    CellRendererRecarga,
    CellRendererImage,
    RechargeDeliverymanModal,
    HistoryStateModal,
    AppLoading,
    CellRendererVerify,
    SendNotificationPrompt,
    RechargePointsModal,
    CellRendererRecargaPuntos,
    CellRendererDeliverymanLevel,
    CellRendererInventorySales,
  },

  setup(_, { root }) {
    const sendNotificationPrompt = ref(false);
    const currentPage = ref(1);
    const first = ref(20);
    const observation = ref('');
    const state = reactive({
      activePromptRecargaPuntos: false,
      activePromptInventorySale: false,
      loading: false,
      //VSPromptStatus
      activePromptStatus: false,
      togglePromptVerify: false,
      togglePromptDeliverymanLevel: false,
      status: null,
      level: null,

      deliverymanStatuses: [
        {
          text: 'Activo',
          value: 'ENABLED',
        },
        {
          text: 'Inactivo',
          value: 'INACTIVE',
        },
        {
          text: 'Inactivo por capacitación',
          value: 'INACTIVE_TRAINING',
        },
        {
          text: 'Inactivo por observación',
          value: 'INACTIVE_OBSERVATION',
        },
        {
          text: 'Deshabilitado',
          value: 'DISABLED',
        },
      ],
      verify_states: [
        {
          text: 'Verificado',
          value: 'verified',
        },
        {
          text: 'No verificado',
          value: 'waiting_verification',
        },
      ],
      levels: [
        {
          text: 'Base',
          value: 'BASE',
        },
        {
          text: 'VIP',
          value: 'VIP',
        },
      ],

      //VSPromptFranquicia
      selectedFranquicia: null,
      activePromptFranquicia: false,
      franquiciasSocio: [],
      franquicias: [
        {
          id: 0,
          name: 'Empanadas Juanito',
        },
        {
          id: 1,
          name: 'Jarris Cabecera',
        },
        {
          id: 2,
          name: 'Jarris Cabecera',
        },
        {
          id: 3,
          name: 'Empanadas Juanito',
        },
      ],

      local_franquicias: [],

      //VSPromptRecarga
      activePromptRecarga: false,
      activePromptHistory: false,
      tipoDeCajaSeleccionado: 'efectivo',
      valorRecarga: 0,
      tiposDeCaja: [
        {
          value: 'banco',
          text: 'Banco',
        },
        {
          value: 'efectivo',
          text: 'Efectivo',
        },
      ],

      //AgGrid
      columnDefs: [
        {
          headerName: 'Estado',
          field: 'status',
          filter: true,
          width: 125,
          cellRendererFramework: 'CellRendererStatus',
        },
        {
          headerName: 'Recarga',
          field: 'recarga',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererRecarga',
        },
        {
          headerName: 'R. Puntos',
          field: 'recarga_puntos',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererRecargaPuntos',
        },
        {
          headerName: 'Vender',
          field: 'sales',
          width: 100,
          cellRendererFramework: 'CellRendererInventorySales',
        },
        {
          headerName: 'Verificación',
          field: 'user',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererVerify',
        },
        {
          headerName: 'Nivel',
          field: 'level',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererDeliverymanLevel',
        },
        {
          headerName: 'Editar',
          field: 'editar',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererEdit',
        },
        {
          headerName: 'Código',
          field: 'code',
          width: 150,
          filter: true,
        },
        {
          headerName: 'Nombre(s)',
          field: 'user.first_name',
          width: 150,
          filter: true,
        },

        {
          headerName: 'Apellido(s)',
          field: 'user.last_name',
          filter: true,
          width: 150,
        },
        {
          headerName: 'Tel. Principal',
          field: 'user.phone',
          filter: true,
          width: 200,
        },
        {
          headerName: 'Cédula',
          field: 'document_number',
          filter: true,
          width: 200,
        },

        {
          headerName: 'Saldo',
          field: 'user.wallet.amount',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererAmount',
        },
        {
          headerName: 'Imagen',
          field: 'profile_picture_url',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Licencia',
          field: 'drivers_license_picture_url',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Documento',
          field: 'identification_document_picture_url',
          filter: true,
          width: 100,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Tarj. Propiedad',
          field: 'property_card_picture_url',
          filter: true,
          width: 120,
          cellRendererFramework: 'CellRendererImage',
        },
        {
          headerName: 'Puntos',
          field: 'user.point.amount',
          width: 100,
          filter: true,
        },

        {
          headerName: '#',
          field: 'id',
          width: 100,
          filter: true,
        },
      ],
      socioSeleccionado: null,
      currentSale: {
        product: null,
        amount: 1,
        cash: 0,
        points: 0,
        account: null,
      },
    });
    const searchQuery = ref('');
    const {
      result: deliverymenResult,
      loading: deliverymenLoading,
      refetch: refetchDeliveryman,
    } = useQuery(ALL_DELIVERYMEN, {
      page: currentPage,
      first: first,
      search: searchQuery,
    });

    const {
      result: inventoryResult,
      loading: inventoryLoading,
      refetch: refetchInventory,
    } = useQuery(ALL_INVENTORIES);
    const inventory = useResult(
      inventoryResult,
      [],
      (data) => data.allInventories
    );

    const { result: accountsResult, loading: accountsLoading } =
      useQuery(ACCOUNTS);
    const accounts = useResult(accountsResult, [], (data) => data.accounts);

    watch(deliverymenLoading, (value) => {
      state.loading = value;
    });

    const deliverymen = useResult(
      deliverymenResult,
      [],
      (data) => data.allDeliverymen.data
    );

    const paginatorInfo = useResult(deliverymenResult, [], (data) => ({
      total: data.allDeliverymen.paginatorInfo.total,
      perPage: data.allDeliverymen.paginatorInfo.perPage,
    }));

    const totalPages = computed(() =>
      Math.ceil(paginatorInfo.value.total / paginatorInfo.value.perPage)
    );
    const { state: agGridState } = useAgGrid({ root });

    const rechargeSuccess = () => {
      state.socioSeleccionado = null;
      agGridState.gridApi.refreshCells();
    };

    const promptRecarga = ({ socio }) => {
      state.socioSeleccionado = socio;
      state.activePromptRecarga = true;
    };

    const promptRecargaPuntos = ({ socio }) => {
      state.socioSeleccionado = socio;
      state.activePromptRecargaPuntos = true;
    };

    const promptInventorySale = ({ socio }) => {
      state.socioSeleccionado = socio;
      state.activePromptInventorySale = true;
    };

    const promptHistory = ({ socio }) => {
      state.socioSeleccionado = socio;
      state.activePromptHistory = true;
    };

    const promptVerify = ({ socio }) => {
      state.socioSeleccionado = socio;
      state.togglePromptVerify = true;
    };

    const promptDeliverymanLevel = ({ socio, level }) => {
      state.socioSeleccionado = socio;
      state.togglePromptDeliverymanLevel = true;
      state.level = level;
      observation.value = '';
    };

    const saveVerify = async (deliverymen) => {
      try {
        await root.$apollo.mutate({
          mutation: UPDATE_DATA_STATUS,
          variables: {
            id: deliverymen.id,
            data_status: deliverymen.data_status,
            observation: observation.value,
          },
        });
        observation.value = '';
        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Actualizaste la verificación correctamente.',
        });
      } catch (error) {
        console.log(error);
        root.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Comprueba tu conexión a internet.',
        });
      }
      agGridState.gridApi.refreshCells();
    };

    const saveDeliverymanLevel = async (deliverymen) => {
      try {
        await root.$apollo.mutate({
          mutation: UPDATE_DELIVERYMAN_LEVEL,
          variables: {
            id: deliverymen.id,
            level: deliverymen.level,
            observation: observation.value,
          },
        });
        observation.value = '';
        state.socioSeleccionado.level = deliverymen.level;

        root.$vs.notify({
          color: 'success',
          title: 'Correctamente Actualizado',
          text: 'Actualizaste el nivel del socio correctamente.',
        });
      } catch (error) {
        state.socioSeleccionado.level = state.level;
        deliverymen.level = state.level;

        console.log(error);
        root.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Comprueba tu conexión a internet.',
        });
      }
    };

    const recargarSocio = () => {
      root.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        cancelText: 'Cancelar',
        acceptText: 'Guardar',
        title: `Confirmar recarga`,
        text: `Recarga a socio ${state.socioSeleccionado.fullName} por valor de ${state.valorRecarga}`,
        accept: () => {
          // Reactividad despues de haber hecho los cambios exitosamente en el servidor

          const valorRecarga = Number(state.valorRecarga);
          // const tipoDeCaja = this.tipoDeCajaSeleccionado;
          state.socioSeleccionado.saldo =
            state.socioSeleccionado.saldo + valorRecarga;
          agGridState.gridApi.refreshCells();
          state.valorRecarga = 0;

          root.$vs.notify({
            color: 'success',
            title: 'Recarga realizada!',
            text: 'Las recarga se realizo de manera satisfactoria.',
          });
        },
        cancel: () => {
          state.activePromptRecarga = true;
        },
      });
    };

    const changeDeliverymanStatus = async () => {
      // Reactividad despues de haber hecho los cambios exitosamente en el servidor
      state.loading = true;
      try {
        await root.$apollo.mutate({
          mutation: UPDATE_DELIVERYMAN_STATUS,
          variables: {
            id: state.socioSeleccionado.id,
            status: state.status,
            observation: observation.value,
          },
        });
        state.socioSeleccionado.status = state.status;
        state.loading = false;

        // agGridState.gridApi.refreshCells();
        root.$vs.notify({
          color: 'success',
          title: 'Estado actualizado!',
          text: 'El estado del socio fue correctamente actualizado.',
        });
      } catch (error) {
        console.log(error);
        state.loading = false;
        root.$vs.notify({
          color: 'danger',
          title: 'No pudimos procesar tu solicitud!',
          text: 'El estado del socio no se pudo actualizar.',
        });
      }
    };

    const changeStatus = ({ status, socio }) => {
      state.socioSeleccionado = socio;
      state.status = status;
      state.activePromptStatus = true;
      observation.value = '';
    };

    const exportAllDeliverymen = () => {
      console.log('Export deliverymen');
      const fileName = 'deliverymen.xlsx';
      root.$http
        .get('api/deliverymen/export', { responseType: 'blob' })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
    };

    const openNotificationPrompt = () => {
      sendNotificationPrompt.value = true;
    };

    const saleProductToDeliveryman = async (deliveryman) => {
      console.log(deliveryman, state.currentSale);
      if (state.currentSale.amount > state.currentSale.product.stock) {
        root.$vs.notify({
          color: 'danger',
          title: 'Sin stock',
          text: `No hay suficiente stock para la cantidad ingresada. Máximo ${state.currentSale.product.stock}`,
        });
        return;
      }

      state.loading = true;
      try {
        await root.$apollo.mutate({
          mutation: CREATE_INVENTORY_SALE,
          variables: {
            input: {
              inventory_id: state.currentSale.product.id,
              deliveryman_id: state.socioSeleccionado.id,
              cash: state.currentSale.cash || 0,
              points: state.currentSale.points || 0,
              amount: state.currentSale.amount || 0,
              account_id: state.currentSale.account.id,
            },
          },
        });
        state.loading = false;

        refetchDeliveryman();
        refetchInventory();

        state.currentSale = {
          product: null,
          amount: 1,
          cash: 0,
          points: 0,
          account: null,
        };

        root.$vs.notify({
          color: 'success',
          title: 'Venta realizada',
          text: 'Se ha vendido con éxito el producto.',
        });
      } catch (error) {
        state.loading = false;
        console.error('❌ saleProductToDeliveryman', error);
        root.$vs.notify({
          color: 'danger',
          title: 'Error al realizar la venta',
          text: `No se pudo realizar la venta correctamente. ${error?.message}`,
        });
      }
    };

    return {
      ...toRefs(state),
      agGridState,
      currentPage,
      deliverymen,
      promptHistory,
      promptRecarga,
      changeDeliverymanStatus,
      rechargeSuccess,
      recargarSocio,
      exportAllDeliverymen,
      changeStatus,
      totalPages,
      searchQuery,
      promptVerify,
      promptDeliverymanLevel,
      saveVerify,
      saveDeliverymanLevel,
      sendNotificationPrompt,
      observation,
      openNotificationPrompt,
      promptRecargaPuntos,
      promptInventorySale,
      deliverymenLoading,
      saleProductToDeliveryman,
      inventoryLoading,
      inventory,
      accounts,
      accountsLoading,
    };
  },

  created() {
    this.agGridState.gridOptions.context = {
      componentParent: this,
    };
  },
});
</script>

<style lang="scss">
.table-loading {
  flex: 0 0 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  .con-vs-loading {
    position: relative;
    background: inherit;
  }
  #div-with-loading {
    width: 200px;
    height: 200px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
}
@import '@/assets/scss/manager/components/sociosTable.scss';
</style>
