<template>
  <div>
    <feather-icon
      icon="ShoppingCartIcon"
      svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
      @click="sell"
    />
  </div>
</template>

<script>
export default {
  methods: {
    sell() {
      this.params.context.componentParent.promptInventorySale({
        socio: this.params.data,
      });
    },
  },
};
</script>

<style></style>
