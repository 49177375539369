<template>
  <div>
    <ValidationObserver ref="observerClave" v-slot="{ invalid }">
      <vs-prompt
        class="socios-table__prompt"
        :active.sync="activator"
        title="Recargar saldo a socio"
        @accept="recharge"
        accept-text="Recargar"
        :is-valid="!invalid"
        cancel-text="Descartar"
      >
        <vs-input
          v-if="deliveryman"
          :value="deliveryman.user.wallet.amount | currencyFormat"
          label="Saldo"
          placeholder="$ 0"
          disabled
          class="w-full"
        />
        <ValidationProvider
          name="recarga"
          v-slot="{ errors }"
          rules="required|numeric|min_value:50"
        >
          <vs-input
            v-model="transactionPayload.amount"
            label="Monto"
            class="w-full mt-3"
            type="number"
          />
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="caja" rules="required">
          <vs-select
            class="w-full mt-3"
            label="Caja"
            v-model="transactionPayload.account_id"
          >
            <vs-select-item :value="null" text="Seleccione una caja" />
            <vs-select-item
              v-for="(item, index) in accounts"
              :key="index"
              :value="item.id"
              :text="item.name"
            />
          </vs-select>
        </ValidationProvider>

        <vs-select
          class="w-full mt-3"
          label="Tipo de Operación"
          v-model="transactionPayload.type"
        >
          <vs-select-item
            v-for="(item, index) in transactionTypes"
            :key="index"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import { RECHARGE_DELIVERYMAN } from '@/graphql/mutations';
import { ACCOUNTS, DELIVERYMEN } from '@/graphql/queries';

export default {
  name: 'RechargeDeliverymanModal',
  data: () => ({
    transactionPayload: {
      user: {
        backend: 'DELIVERYMEN',
        user_id: null,
      },
      amount: 0,
      account_id: null,
      type: 'RECHARGE',
    },
    accounts: [],
    transactionTypes: [
      { text: 'Recarga', value: 'RECHARGE' },
      { text: 'Descarga', value: 'DISCHARGE' },
    ],
  }),
  props: {
    value: Boolean,
    deliveryman: Object,
  },
  computed: {
    activator: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    badResponse(e) {
      console.log(e.graphQLErrors);
      const errorMessages = e.graphQLErrors.map((err) => {
        return err.message;
      });
      const error = errorMessages.join(', ');
      let text;
      if (error === 'null') {
        text = `Ocurrió un error desconocido al generar la transacción, intentelo mas tarde o contacte al administrador`;
      } else {
        text = error;
      }
      this.$vs.notify({
        color: 'danger',
        title: 'Problemas con la transacción',
        text,
      });
    },
    async getAccounts() {
      const { data } = await this.$apollo.query({
        query: ACCOUNTS,
      });
      this.accounts = data.accounts;
    },
    async recharge() {
      this.transactionPayload.user.user_id = this.deliveryman.user.id;
      try {
        await this.$apollo
          .mutate({
            mutation: RECHARGE_DELIVERYMAN,
            variables: this.transactionPayload,
            update: (cache, { data: { makeTransaction } }) => {
              const localData = cache.readQuery({ query: DELIVERYMEN });

              const deliveryman = localData.deliverymen.find(
                (e) => e.id === makeTransaction.deliveryman.id
              );

              if (deliveryman) {
                deliveryman.user.wallet.amount =
                  makeTransaction.deliveryman.wallet.amount;
              }

              cache.writeQuery({
                query: DELIVERYMEN,
                data: localData,
              });
            },
          })
          .then(() => {
            this.$vs.notify({
              color: 'success',
              title: 'Transacción realizada',
              text: 'La transacción se realizó de manera satisfactoria.',
            });
          })
          .catch((error) => {
            this.$vs.notify({
              color: 'danger',
              title: 'Error al hacer la transacción.',
              text: `${error.graphQLErrors.map((r) => r.message)}`,
            });
          });
        this.$emit('recharge-success');
      } catch (e) {
        console.error(e);
        this.badResponse(e);
      }
    },
  },
};
</script>

<style scoped></style>
