<template>
  <div>
    <ValidationObserver ref="observerClave" v-slot="{ invalid }">
      <vs-prompt
        :active.sync="activator"
        title="Recargar puntos a socio"
        @accept="recharge"
        accept-text="Guardar"
        :is-valid="!invalid"
        cancel-text="Descartar"
      >
        <ValidationProvider
          name="recarga"
          v-slot="{ errors }"
          rules="required|numeric|min_value:50"
        >
          <vs-input
            v-model="transactionPayload.amount"
            label="Monto"
            class="w-full mt-3"
            type="number"
          />
          <span class="text-danger text-sm">{{ errors[0] }}</span>
        </ValidationProvider>

        <vs-select
          class="w-full mt-3"
          label="Tipo de Operación"
          v-model="transactionPayload.type"
        >
          <vs-select-item
            v-for="(item, index) in transactionTypes"
            :key="index"
            :value="item.value"
            :text="item.text"
          />
        </vs-select>
      </vs-prompt>
    </ValidationObserver>
  </div>
</template>

<script>
import { useMutation } from '@vue/apollo-composable';
import { defineComponent, reactive } from '@vue/composition-api';
import { useModelWrapper } from '../../../composable/useModelWrapper';
import { MAKE_TRANSACTION_POINTS } from '@/graphql/mutations';
export default defineComponent({
  props: {
    value: { type: Boolean, default: false },
    deliveryman: { type: Object, default: null },
  },
  setup(props, { emit, root }) {
    const { mutate: makeTransactionPointsMutation } = useMutation(
      MAKE_TRANSACTION_POINTS
    );
    const transactionTypes = [
      { text: 'Recarga', value: 'RECHARGE' },
      { text: 'Descarga', value: 'DISCHARGE' },
    ];
    const activator = useModelWrapper(props, emit, 'value');

    const transactionPayload = reactive({
      type: 'DISCHARGE',
      amount: 0,
    });

    const recharge = async () => {
      if (!root.$gates.hasAnyRole('super_admin|national_logistics_leader')) {
        root.$vs.notify({
          color: 'warning',
          title: 'No tienes permisos',
          text: 'No tienes permisos para realizar esta acción',
        });
        return;
      }
      root.$vs.loading();
      const response = await makeTransactionPointsMutation({
        user_id: props.deliveryman.user.id,
        amount: transactionPayload.amount,
        type: transactionPayload.type,
      });
      if (response.data.makeTransactionPoints) {
        root.$vs.notify({
          color: 'success',
          title:
            transactionPayload.type == 'RECHARGE'
              ? 'Recarga de puntos'
              : 'Descarga de puntos',
          text:
            transactionPayload.type == 'RECHARGE'
              ? 'Se han recargado los puntos correctamente'
              : 'Se han descargado los puntos correctamente',
        });
      }
      root.$vs.loading.close();
    };
    return {
      transactionTypes,
      activator,
      recharge,
      transactionPayload,
    };
  },
});
</script>

<style scoped></style>
