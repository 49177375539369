var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"socios-table tw-relative"},[_c('SendNotificationPrompt',{model:{value:(_vm.sendNotificationPrompt),callback:function ($$v) {_vm.sendNotificationPrompt=$$v},expression:"sendNotificationPrompt"}}),_c('ValidationObserver',{ref:"oberserverStatus",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.socioSeleccionado)?_c('vs-prompt',{staticClass:"socios-table__prompt",attrs:{"active":_vm.togglePromptVerify,"title":"Verificar Socio Logistico","accept-text":"Guardar","is-valid":!invalid,"cancel-text":"Descartar"},on:{"update:active":function($event){_vm.togglePromptVerify=$event},"accept":function($event){return _vm.saveVerify(_vm.socioSeleccionado)}}},[_c('vs-select',{staticClass:"w-full",attrs:{"label":"Estado:"},model:{value:(_vm.socioSeleccionado.data_status),callback:function ($$v) {_vm.$set(_vm.socioSeleccionado, "data_status", $$v)},expression:"socioSeleccionado.data_status"}},_vm._l((_vm.verify_states),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('vs-textarea',{staticClass:"mt-3",attrs:{"placeholder":"Observación"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}}),_c('vs-table',{attrs:{"stripe":"","data":_vm.socioSeleccionado.historyDataStatuses,"max-items":"3","pagination":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].id}},[_vm._v(" "+_vm._s(data[indextr].id)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].observation}},[_vm._v(" "+_vm._s(data[indextr].observation)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].status}},[_vm._v(" "+_vm._s(_vm._f("dataStatus")(data[indextr].status))+" ")])],1)})}}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Historial")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" # ")]),_c('vs-th',[_vm._v(" Observación ")]),_c('vs-th',[_vm._v(" Estado ")])],1)],2)],1):_vm._e()]}}])}),(_vm.socioSeleccionado)?_c('recharge-deliveryman-modal',{attrs:{"deliveryman":_vm.socioSeleccionado},on:{"recharge-success":_vm.rechargeSuccess},model:{value:(_vm.activePromptRecarga),callback:function ($$v) {_vm.activePromptRecarga=$$v},expression:"activePromptRecarga"}}):_vm._e(),(_vm.socioSeleccionado)?_c('recharge-points-modal',{attrs:{"deliveryman":_vm.socioSeleccionado},model:{value:(_vm.activePromptRecargaPuntos),callback:function ($$v) {_vm.activePromptRecargaPuntos=$$v},expression:"activePromptRecargaPuntos"}}):_vm._e(),(_vm.socioSeleccionado)?_c('history-state-modal',{attrs:{"deliveryman":_vm.socioSeleccionado},model:{value:(_vm.activePromptHistory),callback:function ($$v) {_vm.activePromptHistory=$$v},expression:"activePromptHistory"}}):_vm._e(),_c('ValidationObserver',{ref:"oberserverStatus",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('vs-prompt',{staticClass:"socios-table__prompt",attrs:{"title":"Cambiar estatus contratista","active":_vm.activePromptStatus,"is-valid":!invalid,"accept-text":"Guardar","cancel-text":"Descartar"},on:{"update:active":function($event){_vm.activePromptStatus=$event},"accept":_vm.changeDeliverymanStatus}},[_c('vs-select',{staticClass:"w-full",attrs:{"label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.deliverymanStatuses),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('vs-textarea',{staticClass:"mt-3",attrs:{"placeholder":"Observación"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}}),(_vm.socioSeleccionado)?_c('vs-table',{attrs:{"stripe":"","data":_vm.socioSeleccionado.historyDeliverymanStatuses,"max-items":"3","pagination":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].id}},[_vm._v(" "+_vm._s(data[indextr].id)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].status}},[_vm._v(" "+_vm._s(_vm._f("deliverymanHistoryStatus")(data[indextr].status))+" ")]),_c('vs-td',{attrs:{"data":data[indextr].observation}},[_vm._v(" "+_vm._s(data[indextr].observation)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].created_at}},[_vm._v(" "+_vm._s(data[indextr].created_at)+" ")])],1)})}}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Historial")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" # ")]),_c('vs-th',[_vm._v(" Estado ")]),_c('vs-th',[_vm._v(" Observación ")]),_c('vs-th',[_vm._v(" Creación ")])],1)],2):_vm._e()],1)]}}])}),_c('ValidationObserver',{ref:"oberserverStatus",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.socioSeleccionado)?_c('vs-prompt',{staticClass:"socios-table__prompt",attrs:{"active":_vm.togglePromptDeliverymanLevel,"title":"Nivel de socio lógistico","accept-text":"Guardar","is-valid":!invalid,"cancel-text":"Descartar"},on:{"update:active":function($event){_vm.togglePromptDeliverymanLevel=$event},"accept":function($event){return _vm.saveDeliverymanLevel(_vm.socioSeleccionado)}}},[_c('vs-select',{staticClass:"w-full",attrs:{"label":"Nivel:"},model:{value:(_vm.socioSeleccionado.level),callback:function ($$v) {_vm.$set(_vm.socioSeleccionado, "level", $$v)},expression:"socioSeleccionado.level"}},_vm._l((_vm.levels),function(item,index){return _c('vs-select-item',{key:index,attrs:{"value":item.value,"text":item.text}})}),1),_c('vs-textarea',{staticClass:"mt-3",attrs:{"placeholder":"Observación"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}}),_c('vs-table',{attrs:{"stripe":"","data":_vm.socioSeleccionado.historyLevels,"max-items":"3","pagination":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr},[_c('vs-td',{attrs:{"data":data[indextr].id}},[_vm._v(" "+_vm._s(data[indextr].id)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].observation}},[_vm._v(" "+_vm._s(data[indextr].observation)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].level}},[_vm._v(" "+_vm._s(data[indextr].level)+" ")]),_c('vs-td',{attrs:{"data":data[indextr].created_at}},[_vm._v(" "+_vm._s(data[indextr].created_at)+" ")])],1)})}}],null,true)},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Historial")])]),_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(" # ")]),_c('vs-th',[_vm._v(" Observación ")]),_c('vs-th',[_vm._v(" Nivel ")]),_c('vs-th',[_vm._v(" Creación ")])],1)],2)],1):_vm._e()]}}])}),_c('ValidationObserver',{ref:"oberserverStatus",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.socioSeleccionado)?_c('vs-prompt',{staticClass:"socios-table__prompt",attrs:{"active":_vm.activePromptInventorySale,"title":"Vender producto a aliado","accept-text":"Vender","is-valid":!invalid,"cancel-text":"Descartar"},on:{"update:active":function($event){_vm.activePromptInventorySale=$event},"accept":function($event){return _vm.saleProductToDeliveryman(_vm.socioSeleccionado)}}},[(_vm.inventoryLoading)?_c('div',[_c('p',{staticClass:"my-3 text-grey text-center"},[_vm._v("Cargando...")])]):_c('div',[_c('vs-select',{staticClass:"w-full",attrs:{"label":"Producto:"},model:{value:(_vm.currentSale.product),callback:function ($$v) {_vm.$set(_vm.currentSale, "product", $$v)},expression:"currentSale.product"}},_vm._l((_vm.inventory),function(product,index){return _c('vs-select-item',{key:index,attrs:{"value":product,"text":product.name}})}),1),_c('vs-select',{staticClass:"w-full",attrs:{"label":"Cuenta de depósito:"},model:{value:(_vm.currentSale.account),callback:function ($$v) {_vm.$set(_vm.currentSale, "account", $$v)},expression:"currentSale.account"}},_vm._l((_vm.accounts),function(account,index){return _c('vs-select-item',{key:index,attrs:{"value":account,"text":account.name}})}),1),_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required|numeric|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Cantidad","type":"number"},model:{value:(_vm.currentSale.amount),callback:function ($$v) {_vm.$set(_vm.currentSale, "amount", $$v)},expression:"currentSale.amount"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Efectivo","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Efectivo","type":"number"},model:{value:(_vm.currentSale.cash),callback:function ($$v) {_vm.$set(_vm.currentSale, "cash", $$v)},expression:"currentSale.cash"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Puntos","rules":"min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full mt-3",attrs:{"label":"Puntos","type":"number"},model:{value:(_vm.currentSale.points),callback:function ($$v) {_vm.$set(_vm.currentSale, "points", $$v)},expression:"currentSale.points"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]):_vm._e()]}}])}),_c('vx-card',[_c('div',{staticClass:"flex flex-wrap justify-between items-center"},[_c('div',{staticClass:"mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"}),_c('div',{staticClass:"flex flex-wrap items-center justify-between ag-grid-table-actions-right"},[_c('vs-input',{staticClass:"mb-4 md:mb-0 mr-4 tw-w-full",attrs:{"placeholder":"Buscar..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('vs-button',{staticClass:"mb-4 md:mb-0",attrs:{"type":"border"},on:{"click":_vm.exportAllDeliverymen}},[_vm._v("Exportar")]),_c('vs-button',{staticClass:"mb-4 ml-3 md:mb-0",on:{"click":_vm.openNotificationPrompt}},[_vm._v("Enviar Notificación")])],1)]),(!_vm.loading && !_vm.deliverymenLoading)?_c('div',[_c('ag-grid-vue',{ref:"agGridTable",staticClass:"ag-theme-material w-100 my-4 ag-grid-table",attrs:{"gridOptions":_vm.agGridState.gridOptions,"columnDefs":_vm.columnDefs,"defaultColDef":_vm.agGridState.defaultColDef,"rowData":_vm.deliverymen,"rowSelection":"multiple","colResizeDefault":"shift","animateRows":true,"floatingFilter":false,"pagination":true,"suppressPaginationPanel":true,"enableRtl":_vm.$vs.rtl}}),_c('vs-pagination',{attrs:{"total":_vm.totalPages,"max":_vm.agGridState.maxPageNumbers},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('div',{staticClass:"h-64 my-24 flex justify-center items-center"},[_c('p',{staticClass:"text-grey"},[_vm._v("Cargando...")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }