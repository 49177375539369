<template>
  <vs-prompt
    class="socios-table__prompt"
    :active.sync="active"
    @accept="sendMessage"
    title="Enviar notifación"
    accept-text="Enviar"
    cancel-text="Descartar"
  >
    <vs-input
      class="w-full mb-3"
      v-model="message.title"
      placeholder="Título"
    />
    <vs-textarea
      v-model="message.body"
      placeholder="Escriba su mensaje aquí"
    ></vs-textarea>
  </vs-prompt>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api';
import { useModelWrapper } from '@/composable/useModelWrapper';
import { useMutation } from '@vue/apollo-composable';
import { SEND_PUSH_NOTIFICATION } from '@/graphql/mutations';
export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  setup(props, { emit }) {
    const active = useModelWrapper(props, emit, 'value');
    const { mutate: sendPushNotification } = useMutation(
      SEND_PUSH_NOTIFICATION
    );
    const message = reactive({
      title: '¡Mensaje de CITIX!',
      body: '¡Saludos!',
    });

    const sendMessage = async () => {
      console.log(message.value);
      // TODO - Use mutation to send message
      const result = await sendPushNotification({
        title: message.title.trim(),
        body: message.body.trim(),
      });
      console.log(result);
      // root.$vs.notify({
      //   color: 'success',
      //   title: 'Notificación enviada!',
      //   text: result.data.sendPushNotification.message,
      // });
    };
    return { active, message, sendMessage };
  },
});
</script>
