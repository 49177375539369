<template>
  <vs-chip
    @click.native="eventEmitter"
    :class="['ag-grid-cell-chip', chipClass(params.data.level)]"
  >
    <span v-if="params.data.level">{{ labeler(params.data.level) }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererDeliverymanLevel',
  computed: {
    chipClass() {
      const classes = {
        BASE: 'base',
        VIP: 'vip',
        DEFAULT: 'base',
      };

      return (value) => {
        return classes[value] ?? classes.DEFAULT;
      };
    },
    labeler() {
      const labels = {
        BASE: 'Base',
        VIP: 'VIP',
        DEFAULT: 'Base',
      };

      return (value) => {
        return labels[value] ?? labels.DEFAULT;
      };
    },
  },
  methods: {
    eventEmitter() {
      this.params.context.componentParent.promptDeliverymanLevel({
        level: this.params.value,
        socio: this.params.data,
      });
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  @apply font-bold;
  &.vip {
    background-color: rgba(var(--vs-primary), 1);

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.base {
    @apply bg-mystic;
    .vs-chip--text {
      @apply text-san-juan;
    }
  }
}
</style>
