<template>
  <div class="socios-logisticos">
    <SociosTable />
  </div>
</template>

<script>
import SociosTable from '@/components/socio/SociosTable.vue';
export default {
  components: {
    SociosTable,
  },
};
</script>
