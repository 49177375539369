<template>
  <vs-chip
    @click.native="emitirEvento"
    :class="['ag-grid-cell-chip', chipClass(params.value)]"
  >
    <span v-if="params.value">{{ params.value | allyStatus }}</span>
  </vs-chip>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
    chipClass() {
      return (value) => {
        if (value === 'ENABLED') return 'ACT';
        else if (value === 'INACTIVE') return 'INT';
        else if (value === 'INACTIVE_TRAINING') return 'IPC';
        else if (value === 'INACTIVE_OBSERVATION') return 'IPO';
        else if (value === 'DISABLED') return 'DES';
        else '';
      };
    },
  },
  methods: {
    emitirEvento() {
      this.params.context.componentParent.changeStatus({
        status: this.params.value,
        socio: this.params.data,
      });
    },
  },
};
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  @apply font-bold;
  &.ACT {
    background-color: rgba(var(--vs-primary), 1);

    .vs-chip--text {
      @apply text-white;
    }
  }

  &.IPC,
  &.IPO,
  &.INT {
    @apply bg-mystic;
    .vs-chip--text {
      @apply text-san-juan;
    }
  }
  &.DES {
    @apply bg-slate-gray;
    .vs-chip--text {
      @apply text-white;
    }
  }
}
</style>
