<template>
  <div>
    <feather-icon
      icon="CheckIcon"
      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
      :class="this.params.data.data_status == 'verified' ? 'text-primary' : ''"
      @click="verifiedSocio"
    />
  </div>
</template>

<script>
export default {
  methods: {
    verifiedSocio() {
      this.$gates.hasPermission('verify_allies') &&
        this.params.context.componentParent.promptVerify({
          socio: this.params.data,
        });
    },
  },
};
</script>

<style></style>
